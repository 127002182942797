@import "~bootstrap/scss/bootstrap";
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css");

// Variables
$white: #fff;
$dark-gray: #333;
$light-gray: #f5f5f5;
$highlight-color: #830b13;
$box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
$border-radius: 10px;
$card-shadow: 0 0 110px rgba(0, 0, 0, 0.25);

html {
  scroll-behavior: smooth;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

// Body Styling
body {
  position: relative;
  background-color: $light-gray;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex-grow: 1;
}

ul {
  list-style-type: disc;
  color: $dark-gray;
}
// Red Pills (Top Info Section)
.top-info {
  position: absolute;
  display: inline-block; /* Only take as much space as needed */
  margin-left: auto; /* Push to the right */
  top: 0;
  right: 1px;
  background-color: #830b13;
  z-index: 1000;

  .content {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  span {
    font-size: 0.9rem; // Text size for red pills
    display: inline-flex;
    align-items: center;
    font-weight: bold;

    i {
      margin-right: 8px; // Space between icon and text
      font-size: 1rem; // Icon size
    }
  }

  .me-4 {
    margin-right: 20px; // Spacing between each item
  }

  // Hide pills on mobile screens
  @media (max-width: 991px) {
    display: none;
  }
}

.top-info.sticky-class {
  position: fixed; /* Becomes sticky when scrolling */
  top: 0;
  right: 0;
}

// Header
header {
  background-color: $light-gray;
  .navbar {
    background-color: $white;

    .navbar-brand img {
      width: 150px; // Adjusted logo size
    }

    .navbar-nav {
      translate: (-30%);
      @media (max-width: 991px) {
        // Remove for mobile
        translate: none;
      }
    }
    .nav-link {
      color: $dark-gray;
      font-weight: 600;
      font-size: 1.25rem;

      &:hover {
        color: $highlight-color;
      }
    }

    // Offcanvas Menu
    .offcanvas {
      background-color: $white;

      .offcanvas-title {
        font-weight: bold;
        color: $dark-gray;
      }

      .categories-box {
        @media (min-width: 992px) {
          display: none;
        }

        h5 {
          font-size: 1.2rem;
          font-weight: bold;
          color: $dark-gray;
          text-align: center;
        }

        ul {
          list-style: none;
          padding: 0;

          li {
            margin-bottom: 10px;

            a {
              color: $dark-gray;
              text-decoration: none;
              font-weight: 600;

              &:hover {
                color: $highlight-color;
              }
            }

            // Nested list for subcategories
            ul {
              margin-left: 15px;
              list-style-type: disc;

              li {
                margin-bottom: 5px;

                a {
                  font-size: 0.9rem;
                  color: $dark-gray;

                  &:hover {
                    color: $highlight-color;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// Hero Section
.hero-section {
  position: relative;
  max-height: 50vh;

  .hero-image {
    img {
      width: 100%;
      max-height: 50vh;
      object-fit: cover; // Maintain aspect ratio
    }

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(131, 11, 19, 0.35); // Solid color overlay
    }
  }

  .hero-content {
    position: absolute;
    top: 30%; // Adjust position higher
    left: 5%;
    transform: translateY(-30%);
    color: $white;
    text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.7);

    .hero-title {
      line-height: 1.6;
      z-index: 2;
      font-size: 2rem;
      font-weight: bold;
    }

    .hero-subtitle {
      font-size: 1rem;
    }
  }

  // Categories Box for Desktop
  .categories-box {
    border-radius: 5px;
    position: absolute;
    top: 30%;
    right: 2%;
    transform: translateY(-30%);
    width: 600px; // Adjust width for consistency
    background-color: rgba(255, 255, 255, 0.8);
    padding: 15px;
    box-shadow: $box-shadow;

    h5 {
      margin-bottom: 20px;
      font-size: 1.2rem;
      font-weight: bold;
      color: $dark-gray;
      text-align: center;
    }

    .d-flex {
      display: flex;
      justify-content: space-between;
      gap: 20px;
    }

    ul {
      list-style: none;
      padding: 0;

      li {
        font-size: 1.125rem;
        margin-bottom: 10px;

        a {
          color: $dark-gray;
          text-decoration: none;
          font-weight: 600;

          &:hover {
            color: $highlight-color;
          }
        }

        // Nested list for subcategories
        ul {
          margin-left: 15px;
          list-style-type: disc;

          li {
            margin-bottom: 5px;

            a {
              font-size: 0.9rem;
              color: $dark-gray;

              &:hover {
                color: $highlight-color;
              }
            }
          }
        }
      }
    }
  }
}

// Responsive Design for Categories Box
@media (max-width: 991px) {
  .categories-box {
    display: none; // Hide categories box on smaller screens
  }
}

.inventory-section {
  .inventory-heading {
    color: #7b0a11;
  }

  .card {
    box-shadow: $card-shadow;

    .card-title {
      color: #7b0a11;
    }

    .card-text {
      color: $dark-gray;
    }

    .arrow-link {
      display: inline-block;
      margin-top: 10px;
      font-size: 24px;
      color: $highlight-color;
      text-decoration: none;

      &:hover {
        color: darken($highlight-color, 10%);
      }
    }
  }
  .btn-show-more {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    background-color: #7b0a11;
    color: $white;
    width: 290px;
    height: 65px;
    border: none;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-bottom: 50px;

    &:hover {
      background-color: darken(#7b0a11, 10%);
    }
  }

  .card-row {
    gap: 50px; // Adjust this value to increase/decrease the gap
  }

  .hover-card {
    transition: transform 0.3s ease;
  }

  .hover-card:hover {
    transform: translateY(-10px);
  }
}

// About Us Section Styling
.about-us__section {
  .about-us__heading {
    color: #7b0a11;
  }
}
.about-us__text {
  padding: 20px;
  background-color: $white;
  border-radius: $border-radius;
  box-shadow: $box-shadow;
}

// Services Section Styling
.services__section {
  margin-bottom: 50px;
  .our-services-heading {
    color: #7b0a11;
  }
  .img-fluid {
    border-radius: $border-radius;
  }
  .services__text {
    padding: 20px;
    background-color: $white;
    border-radius: $border-radius;
    box-shadow: $box-shadow;
  }
}

.footer {
  padding: calc(1rem + 1vw) 0; // ✅ Adjust padding dynamically based on viewport size
  text-decoration: none;
  background-color: #8b0000;
  margin-bottom: 0;
  padding-bottom: 0;
  position: relative;

  .footer-content {
    color: $white;
  }

  img {
    max-width: 130px; // ✅ Ensures logo is not too large
    height: auto;
  }

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  .email {
    text-decoration: none;
  }
}

.breadcrumb-container {
  font-weight: bold;
  position: relative;
  background-image: url("/src/assest/industrial-metal-drill-machine-metalworking-workshop-min.jpg"); // Fixed background image
  background-size: cover;
  background-position: center;
  padding: 40px 20px;

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(131, 11, 19, 0.35);
    z-index: 1;
  }

  .breadcrumb {
    position: relative;
    z-index: 2; // Ensure breadcrumb text appears above the overlay
    font-size: 18px;
    color: $white;
    display: flex;
    align-items: center;

    a {
      color: $white;
      text-decoration: none;
      font-weight: bold;

      &:hover {
        text-decoration: underline;
      }
    }

    i {
      color: $white;
      font-size: 16px;
      margin: 0 5px;
      vertical-align: middle;
    }

    span {
      font-weight: normal;
    }
  }
}

.breadcrumb-container {
  .breadcrumb {
    font-size: 1.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.navbar-toggler:focus {
  outline: none;
  box-shadow: none;
}

.breadcrumb-container + .container {
  margin-top: 50px; /* Increased top spacing */
  margin-bottom: 50px; /* Increased bottom spacing */
}

.card-row {
  gap: 50px;
}

#category-cards {
  .product-card {
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    cursor: pointer; // Changes the cursor to indicate it's clickable

    &:hover {
      transform: translateY(-5px); // Slight lift effect
      box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2); // Smooth shadow effect
    }
  }
}

#product-section {
  @media (max-width: 767px) {
    .row {
      gap: 30px; // ✅ Smaller spacing for mobile
    }
  }
  .image-thumbnails {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    .thumbnail-img {
      width: 100px; // Increased width
      height: 80px; // Adjusted height to maintain aspect ratio
      object-fit: cover;
      cursor: pointer;
      border: 2px solid transparent;
      transition: border 0.2s ease-in-out;

      &:hover,
      &.active {
        border: 2px solid red;
      }
    }
  }

  @media (max-width: 767px) {
    .image-thumbnails {
      display: none !important; // ✅ Hides thumbnails on mobile
    }
  }
}

// Hero section heading reducing size in differents breakpoints
@media (max-width: 991px) {
  #about-us .row,
  #services .row {
    gap: 20px; // ✅ Adds space between image (left) and text box (right) on mobile
  }
}

@media (max-width: 1187px) {
  .desktop-categories {
    width: 500px !important;
  }
}

@media (max-width: 500px) {
  .hero-title {
    font-size: 2rem !important;
  }

  .hero-subtitle {
    font-size: 1.2rem !important;
  }

  .footer {
    font-size: 70% !important;
  }

  .contact-section {
    background-color: #f5f5f5; // ✅ Set the background color
    padding: 50px 0;
  }

  #contact-form {
    max-width: 600px; // ✅ Prevent form from being too wide
    margin: auto; // ✅ Center the form
  }

  #contact-form button {
    background-color: #8b0000 !important; // 🔴 Your main red color
    border: none;
    width: 50%; // 📏 Adjust button width
    display: block;
    margin: 20px auto 0;
    padding: 10px 15px;
    font-size: 1rem;
    font-weight: bold;
    transition: background-color 0.3s ease; // ✅ Smooth transition effect
  }

  #contact-form button:hover {
    background-color: #7a0000 !important; // 🔴 Slightly darker red on hover
    cursor: pointer;
  }

  #aboutUsCarousel img {
    height: 350px; /* Adjust height as needed */
    object-fit: cover;
  }

  #servicesCarousel img {
    height: 350px; /* Adjust height as needed */
    object-fit: cover;
  }
}

.carousel-inner {
  border-radius: 10px;
}

.floating-contact-btn {
  text-decoration: none;
  z-index: 10;
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 12px 18px;
  background-color: $highlight-color;
  color: white;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 25px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  opacity: 1; // ✅ Always visible
  transform: none; // ✅ No animations, no transitions
  pointer-events: all;

  &:hover {
    background-color: darken($highlight-color, 10%);
  }

  @media (max-width: 768px) {
    bottom: 15px;
    right: 15px;
    padding: 10px 15px;
    font-size: 0.9rem;
  }
}

@media (max-width: 800px) {
  .hero-title {
    font-size: 1.5rem !important;
  }
}

@media (max-width: 430px) {
  .hero-title {
    font-size: 1.5rem !important;
    text-align: center;
  }
  .hero-content {
    transform: translateY(0%) !important;
  }
}

.mobile-categories ul li a {
  color: #333 !important; /* Replace with your preferred color */
}

.mobile-categories ul li a:hover {
  color: $highlight-color !important; /* Change hover color */
}

#product-section {
  .carousel-inner img {
    width: 100%; /* Make sure it fits within the container */

    height: 300px; /* Fix height to maintain layout */
    object-fit: cover; /* Ensures images are cropped instead of stretched */
    display: block;
    margin: auto; /* Centers the image */
  }
}

.thumbnail {
  width: 80px; /* Fixed width */
  height: 80px; /* Fixed height */
  object-fit: cover; /* Ensures image fits */
  border-radius: 8px; /* Optional rounded corners */
}

.thumbnail.active {
  border: 2px solid red; /* Highlight active thumbnail */
}

.card-img-top {
  width: 100%; /* Ensures images take full width of the card */
  height: 200px; /* Set a fixed height for consistency */
  object-fit: cover; /* Ensures images don't stretch, but crop to fit */
}
